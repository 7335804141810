@tailwind base;
@tailwind components;
@tailwind utilities;

.send-button {
  box-shadow: 0 2px 6px 0 rgb(57 175 209 / 50%);
  font-size: 23px;
  height: 38px;
  line-height: 19px;
  padding-left: 10px;
  width: 57px;
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6{
  margin:10px 0;
  font-weight:normal!important;
}

@media screen and (max-height:850px){
  #overlay {
    height: 200%;
  }
}

.tw1-h-screen2 {
  height: 200%!important;
}

.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.tags li {
  float: left;
}

.tag {
  background: #fff;
  border-radius: 25px;
  color: #676767;
  display: inline-block;
  line-height: 26px;
  padding: 4px 21px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  border: 1px solid #bebebe;
}


.tag:hover {
  background-color: crimson;
  color: white;
}

.tags .active {
  background-color: #e8f0fe;
  color: #1967d2;
  border: none;
  font-weight: bold;
}

.tag:hover::after {
  border-left-color: crimson;
}

.tag-title {
  font-size: 18px;
  color: rgb(0, 0, 0);
  margin-top: 30px;
  font-weight: bold!important;
}

.nav-wrapper {
  width: 300px;
  margin: 20px auto;
  text-align: center;
}
.sl-nav {
  display: inline;
}
.sl-nav ul {
  margin:0;
  padding:0;
  list-style: none;
  position: relative;
  display: inline-block;
}
.sl-nav li {
  cursor: pointer;
  padding-bottom:10px;
}
.sl-nav li ul {
  display: none;
}
.sl-nav li:hover ul {
  position: absolute;
  top:-125px;
  right:-225px;
  background: #fff;
  padding-top: 0px;
  z-index: 1;
  border-radius:5px;
  width: 600px;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.sl-nav li:hover .triangle {
  position: absolute;
  top: -15px;
  right: 11px;
  z-index: 10;
  height: 14px;
  overflow: hidden;
  width: 30px;
  background: transparent;
}
.sl-nav li:hover .triangle:after {
  content: '';
  display: block;
  z-index: 20;
  width: 15px;
  transform: rotate(314deg) translateY(0px) translatex(10px);
  height: 15px;
  background: #fff;
  border-radius:2px 0px 0px 0px;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
}
.sl-nav li ul li {
  position: relative;
  text-align: left;
  background: transparent;
  padding: 15px 15px;
  padding-bottom:0;
  z-index: 2;
  font-size: 15px;
  color: #3c3c3c;
  width: 33%;
}
.sl-nav li ul li:last-of-type {
  padding-bottom: 15px;
}
.sl-nav li ul li span {
  padding-left: 5px;
}
.sl-nav li ul li span:hover, .sl-nav li ul li span.active {
  color: #146c78;
}
.sl-flag {
  display: inline-block;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.4);
  width: 15px;
  height: 15px;
  background: #aaa;
  border-radius: 50%;
  position: relative;
  top: 2px;
  overflow: hidden;
}
.flag-de {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTM0A1t6AAAAPUlEQVQ4T+3HMQ0AIBTE0NOHM8x9B7hgh71bIWGieUvze1m7kHGBr/AVvsJX+EpmP5dV5/gKX+ErfIUvVDYcX2NMxQC8PAAAAABJRU5ErkJggg==');
  background-size: cover;
  background-position: center center;
}
.flag-usa {
  background-size: cover;
  background-position: center center;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTM0A1t6AAABhUlEQVQ4T2Ows82PjGixsc4LD2tysC/09Kjw8622tyuICG8u0w/cpGSCBzF4e1VmZkzw9anOzOj38a4KCW4IC22ECHYk1l9tn4gHMeTlTnZxLikvm+XiUpKW2hvgX+vnV5OVOQEoOGfOtv94AYOzU3Fd7XxHh6Lq6rlurqUx0W0J8Z1AnbW18yotonaYuOJBDBXls4A+bGpaBCTz86YEBtQCvVBSPAPIbY0oP1/aiAcxABU1Ny+2tclvbFjo5FgUF9uenNwNDLnmpkWEnV1TPRcY1O1tS4H6i4umA/0MDK2K8tlAwRqHpP1uoXgQKKraWpcClTY3LQZaCLQ5NaUX5OaWJY3++SeTC/AgBmA4AXUClUJs9ver8fKsAAYEUJCws4G21dXNB1oFdD/Qz8DQTk4C+bm2dn6DZ9bRiDQ8iAEYt8CoBpK5YBIYw0AEEZwSXX4oMB4PYoC6gCzAcDqrjGzEsMfen2xEmbMv1rSTjRi26dqRjShz9o2+6WQjBrSShQSkZAIADvW/HLrLY6cAAAAASUVORK5CYII=');
}

@media (max-width: 768px) {
  .sl-nav li:hover ul {
    right: -90px;
    width: 360px;
  }
}

@media (max-width: 490px) {
  .sl-nav li:hover ul {
    right: -75px;
    width: 300px;
  }
}

@media (max-width: 350px) {
  .sl-nav li:hover ul {
    right: -64px;
    width: 280px;
    top: -218px;
  }

  .sl-nav li ul li {
    width: 50%;
  }
}

.feedback-input {
  width: 100%;
  height: 110px;
  outline: none;
  border: 1px solid #d2d2d2;
  resize: none;
  font-size: 1rem;
}